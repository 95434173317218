.filterArrowsAnimation {
    animation: rotateAnimation 0.5s ease-in-out forwards;
  }
  
  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  
  .headerCell {
    font-family: 'new-hero, sans-serif';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; 
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    padding: 8px 8px;
  }
  
  .noneFilter {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  /* .ims-datatable .MuiTable-root :not(tfoot) .MuiTableRow-root:nth-child(even) {
    background-color: #f5f5f5;
  } */
  
  .ims-datatable table > tbody > tr > td:first-child  {
    padding: 2px 8px 2px 16px;
  }
  
  .ims-datatable table > tbody > tr:first-child td  {
    padding: 2px 8px;
  }
  
  .ims-datatable table > tbody > tr:first-child td > div.MuiFormControl-root > div.MuiInputBase-root {
    padding: 0px 8px !important;
  }
  
  .ims-datatable table > tbody > tr:first-child td > div.MuiFormControl-root > div.MuiInputBase-root input{
    color: #333333 !important;
    font-family: 'new-hero, sans-serif';
    font-size: 13px !important;
    /* font-weight: 600 !important; */
    line-height: 18px;
    letter-spacing: 0;
    height: 18px;
  }
  
  .ims-datatable table > tbody .MuiFormControl-root .MuiInputBase-root::before,
  .ims-datatable table > tbody .MuiFormControl-root .MuiInputBase-root::after {
    border: none !important;
  }
  
  .ims-datatable table > tbody input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
  }
/* .checkbox-all + .MuiBox-root {
    display: none;
}
table thead tr th {
    border-bottom: 3px solid #e9ecef !important;
}
table tbody tr td {
    border-bottom: 0 !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.action-btn button {
    border: 1px solid #AAAAAA;
    padding: 0px 22px 0px 8px;
} */

.mui-cs-table table > thead {
  display: table-header-group;
  position: sticky;
  top: 0;
  z-index: 10;
}
.mui-cs-table table > thead > tr > th {
  z-index: auto;
  text-transform: uppercase;
  font-size: 12px;
  color: #2D3748;
  line-height: 24px;
  font-weight: 700;
}
.mui-cs-table table > thead > tr > th:first-child > .MuiCheckbox-root {
  margin-left: 8px;
}
.mui-cs-table table > tbody > tr {
  font-size: 14px;
}
.mui-cs-table table > tbody > tr:empty {
  display: none;
}
.mui-cs-table table > tbody > tr > td {
  color: #333333 !important;
  font-family: 'new-hero, sans-serif';
  font-size: 13px !important;
  /* font-weight: 600 !important; */
  line-height: 18px;
  letter-spacing: 0;
  padding: 2px 8px;
  text-align: left;
}
.mui-cs-table table > tbody > tr > td > div.MuiTextField-root {
  float: none !important;
  width: 120px;
}
.mui-cs-table table > tbody > tr > td:last-child > div {
  justify-content: center;
}
.mui-cs-table table tfoot.MuiTableFooter-root .MuiTablePagination-root {
  overflow: hidden !important;
}
.mui-cs-table table tfoot.MuiTableFooter-root .MuiTablePagination-root .MuiInputBase-root {
  width: 100px !important;
}
.mui-cs-table .MuiInputBase-root.MuiInputBase-formControl .MuiSelect-select {
  padding: 8px 10px !important;
  width: 100% !important;
  display: flex;
  margin: -8px -11px;
}
.mui-cs-table .MuiInputBase-root.MuiInputBase-formControl .MuiSelect-icon {
  display: block;
  font-size: 18px !important;
}
.mui-cs-table .MuiFormControl-root .MuiFormHelperText-root.Mui-error {
  margin: 0;
}
.mui-cs-table .MuiInputBase-root.MuiInputBase-formControl.Mui-error, .facility-add-lang .MuiInputBase-root.MuiInputBase-formControl.Mui-error {
  border-color: red;
}
.mui-cs-table table tfoot tr td .MuiTablePagination-select {
  display: flex;
}
.mui-cs-table table tfoot tr td svg.MuiSelect-icon.MuiTablePagination-selectIcon {
  display: block !important;
  right: 10px;
  font-size: 18px !important;
}
.mui-cs-table .MuiTablePagination-displayedRows,
.mui-cs-table .MuiTablePagination-selectLabel {
  display: none !important;
}
.mui-cs-table .MuiTablePagination-select,
.mui-cs-table [class^=MTablePaginationInner-root] .MuiTypography-root.MuiTypography-caption {
  /* font-family: 'new-hero, sans-serif'; */
}
.mui-cs-table [class*=MTableToolbar-searchField] > .MuiInputBase-root::before,
.mui-cs-table [class*=MTableToolbar-searchField] > .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: none;
}
.has-error .true__control, .has-error .true__control:hover {
  border: 1px solid #fd5c70;
  box-shadow: none;
}
table .checkbox-all + .MuiBox-root > .MuiBox-root > .material-icons-round, table .table-action + div {
  display: none;
}
table.table-selection > thead > tr > th:first-child, table.table-selection > tbody > tr > td:first-child {
  width: 40px;
  padding: 0;
  padding-left: 12px;
}
table.table-selection > thead > tr > th:not(:first-child) {
  vertical-align: top;
}
table.table-selection > thead > tr > th:first-child .MuiCheckbox-root, table.table-selection > tbody > tr > td:first-child .MuiCheckbox-root {
  border-radius: 0;
}
/* Border for not checked or disabled */
table.table-selection > thead > tr > th:first-child .MuiCheckbox-root:not(.Mui-checked):not(.Mui-disabled),
table.table-selection > tbody > tr > td:first-child .MuiCheckbox-root:not(.Mui-checked):not(.Mui-disabled) {
  border: 3px solid #787878;
}

/* Border for disabled */
table.table-selection > thead > tr > th:first-child .MuiCheckbox-root.Mui-disabled,
table.table-selection > tbody > tr > td:first-child .MuiCheckbox-root.Mui-disabled {
  border: 3px solid #dfdfdf;
}

