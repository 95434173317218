.MuiTabs-flexContainer .MuiTab-root {
    font-size: 14px;
    font-weight: 500;
}
.MuiTabs-flexContainer .Mui-selected {
    background: linear-gradient(135deg, #00b5e2 0%, #032fca 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
    /* text-fill-color: transparent; */
}
.MuiTabs-indicator {
    border-bottom: 2px solid #00b5e2;
    box-shadow: none !important;
    border-radius: 0 !important;
}