.cs-stepper .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel svg {
    display: none;
}
.cs-stepper .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    width: 18px;
    height: 18px;
    border-radius: 50px;
    position: relative;
    z-index: 1;
    bottom: 2px;
    background-color: #ffffff;
    border: 2px solid rgba(12, 35, 64, 0.3);
}
.cs-stepper .active-wizard .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    border: 3px dashed #0c2340;
    background-color: #ffffff;
}
.cs-stepper .Mui-completed .MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
    background-color: #0c2340;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='red' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.49668 6.92207L0.89043 4.31582L0.00292969 5.19707L3.49668 8.69082L10.9967 1.19082L10.1154 0.30957L3.49668 6.92207Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
}

.test {
}
