.active button,
.active button:focus,
.active button:hover {
    box-shadow: 0px 0px 12px rgb(41 62 143 / 50%) !important;
    border-radius: 22px;
    background: linear-gradient(135deg, #00b5e2 0%, #032fca 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    /* text-fill-color: transparent; */
}
