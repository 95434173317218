.card-print {
    display: none;
}
@media print {
    .card-print {
        display: block;
    }
    @page {
        size: auto;
        margin: 5mm 5mm 5mm 5mm;
    }
}
